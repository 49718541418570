@import "slick";
// @import "slick-theme";
@import "bootstrap";
@import "grid";

body {
	padding-top: 103px;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	color: #585858;
	@media (max-width: 992px) {
		padding-top: 83px;
	}
}

@font-face{
	font-family: MoonFlowerBold;
	src: url('../fonts/moon-flower-bold.ttf');
}

@font-face{
	font-family: MoonFlower;
	src: url('../fonts/moon-flower.ttf');
}

ol, ul {
	margin-top: 0;
	margin-bottom: 10px;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
	font-family: MoonFlower, sans-serif;
	font-weight: 600;
	line-height: 1.1;
	padding: 0;
	margin: 0;
}

img {
	height: auto;  
}

// Input
.input-group {
	width: 100%;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
textarea,
#contactform input[type="text"],
#contactform input[type="password"],
#contactform input[type="email"],
#contactform input[type="tel"],
#contactform textarea {
	width: 100%;
	height: auto;
	background-color: #FFFFFF;
	font-size: 18px;
	border: none;
	background-color: #D9D9D9;
	border: 1px solid #D9D9D9;
	resize: none;
	border-radius: 0px;
	padding: 10px 15px;
	outline: none;
	margin-bottom: 18px;

	&.form-error {
		border-color: #DA6868;
	}
}

label {
	font-size: 18px;
	font-weight: 400;
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
}

textarea {
	padding: 7px 15px;
	height: 237px;
	border-radius: 0px;
}


#contactform {
	.help-block {
		color: #FFFFFF;
		padding: 8px 10px;
		margin: 0 0 10px 0;
		background: #EF6F6F;
		border-radius: 4px;
	}
}

img {
	max-width: 100%;
}

$donker-blauw: #28678E;
$licht-blauw: #54A3D4;
$blauw: #3685B6;
$h2-main: #3E97D2;

.flex {
	display: flex;
}

body.sitekick {
	margin-top: 70px;

	.navigation-section {
		top: 70px;
	}
}

//.inline-edit {
//	ul.inline-edit-controls,
//	.redactor-box ul.redactor-toolbar {
//		> li {
//			width: auto;
//			background: none !important;
//		}
//	}
//}

// Navigation section
.navigation-section {
	width: 100%;
	height: auto;
	background-color: #FFFFFF;
	padding-top: 15px;
	padding-bottom: 15px;
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
	width: 100%;
	position: fixed;
	z-index: 99;
	top: 0;

	// Navicon
	.navicon {
		float: right;
		margin-top: 15px;
		display: none;
	}

	// Logo
	.logo {
		float: left;
		width: 260px;
		position: absolute;
		top: -44px;
		transition: .3s all ease;
		@media (max-width: 1200px){
			top: -10px;
			width: 160px;
		}
		&.scrolled-logo{
			width: 175px;
			top: -32px;
			@media (max-width: 1200px){
				top: -10px;
				width: 160px;
			}
			@media(max-width: 992px){
				width: 125px;
			}
		}
	}

	// Main navigation
	ul.main-navigation {
		float: right;
		list-style-type: none;
		padding: 0;
		margin: 0;
		margin-top: 50px;

		// List item
		li {
			display: inline;
			margin-left: 20px;
			margin-right: 20px;
			position: relative;
			&:last-child {
				margin-right: 0;
			}
			&:first-child{
				margin-top: 100px;
			}

			// List item A attribute
			a {
				color: #3D3D3D;
				font-size: 17px;
				transition: all .3s;

				// Link hover
				&:hover {
					color: #d60c8c;
					text-decoration: none;
				}
			}

			&.active a {
				color: #d60c8c;
			}

			// Sub navigation
			ul.sub-navigation {
				position: absolute;
				left: -15px;
				padding: 0;
				margin: 0;
				width: 200px;
				display: none;
				padding-top: 40px;

				// Sub navigation list item
				li {
					display: block;
					margin: 0;
					a {
						display: inline-block;
						padding: 10px 15px;
						width: 100%;
						border-bottom: 1px solid rgba(0, 0, 0, 0.1);
						background-color: #FFFFFF;
					}

					// Rounded borders sub nav
					&:first-child a {
						border-top-left-radius: 5px;
						border-top-right-radius: 5px;
					}
					&:last-child a {
						border-bottom-left-radius: 5px;
						border-bottom-right-radius: 5px;
						border: none;
					}
				}
			}
		}
	}

	// Top nav
	ul.top-nav {
		right: 15px;
		position: absolute;
		list-style-type: none;
		padding: 0;
		li {
			display: inline-block;
			a {
				color: #585858;
				font-size: 17px;
				background-size: 20px 20px;
				background-position: left center;
				background-repeat: no-repeat;
				display: block;
				padding-top: 5px;
				padding-bottom: 5px;
				padding-left: 25px;
				&.support{
					border: 2px solid #d60c8c;
					border-radius: 3px;
					padding-left: 15px;
					padding-right: 15px;
					margin-left: 15px;
					background-color: #d60c8c;
					color: #fff;
					transition: .3s ease all;
					&:hover{
						text-decoration: none;
						color: #d60c8c;
						background-color: rgba(0,0,0,0);
					}
				}
			}

			&:first-child a {
				background-image: url('../img/work-by.png');
				margin-right: 20px;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	@media (max-width: 992px) {
		padding-bottom: 28px;
		padding-top: 17px;

		// Logo
		.logo {
			width: 125px;
			float: none;
		}

		// Navicon
		.navicon {
			display: block;
		}

		// Main navigation
		ul.main-navigation {
			float: none;
			display: none;
			height: 100vh;
			margin-top: 0;
			max-height: 92vh;
			overflow-y: scroll;

			// List item
			li {
				display: inline-block;
				margin-left: 0px;
				margin-right: 0px;
				width: 100%;
				display: none;

				// List item A attribute
				a {
					display: inline-block;
					width: 100%;
					padding: 10px 0px;
					border-bottom: 1px solid rgba(0, 0, 0, .1);
				}

				&:last-child a {
					border: none;
				}

				// Sub navigation
				ul.sub-navigation {
					position: relative;
					left: 0px;
					width: 100%;
					padding-top: 0px;
				}
			}
		}

		// Top nav
		ul.top-nav {
			display: none;
		}
	}
}

// Button
.button,
a.button {
	font-size: 20px;
	background-color: #d60c8c;
	color: #FFFFFF;
	padding-left: 20px;
	padding-right: 40px;
	padding-top: 8px;
	padding-bottom: 8px;
	// margin-top: 30px;
	display: inline-block;
	// margin-bottom: 115px;
	text-decoration: none;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 600;
	transition: background-color 0.3s ease-in-out;
	-webkit-transition: background-color 0.3s ease-in-out;
	transition: .3s;
	border: 2px solid #d60c8c;
	border-radius: 6px;
	outline: none;
	outline-style: none;
	position: relative;
	&:after{
		content: '';
		background-image: url('../img/arrow-right-white.svg');
		background-repeat: no-repeat;
		position: absolute;
		background-size: 100% 100%;
		top: 37%;
		right: 10%;
		width: 13px;
		height: 13px;
		transition: .3s;
		opacity: 1;
	}
	&:before{
		content: '';
		background-image: url('../img/arrow-right-pink.svg');
		background-repeat: no-repeat;
		position: absolute;
		background-size: 100% 100%;
		top: 37%;
		right: 10%;
		width: 13px;
		height: 13px;
		transition: .3s;
		opacity: 0;
	}
	&:hover{
		background-color: #fff;
		color: #d60c8c;
		&:after{
			opacity: 0;
		}
		&:before{
			opacity: 1;
		}
	}
	&.parkeren{
		background-color: #f47b20;
		border: 2px solid #f47b20;
		&:after{
			text-decoration: none;
			background-repeat: no-repeat;
			top: 37%;
			right: 7%;
			width: 13px;
			height: 13px;
			background-image: url('../img/arrow-right-white.svg');
			opacity: 1;
		}
		&:before{
			text-decoration: none;
			background-repeat: no-repeat;
			top: 37%;
			right: 7%;
			width: 13px;
			height: 13px;
			background-image: url('../img/arrow-right-orange.svg');
			opacity: 0;
		}
		&:hover {
			color: #f47b20;
			&:after{
				opacity: 0;
			}
			&:before{
				opacity: 1;
			}
		}
	}
	&.toezicht{
		border: 2px solid #d60c8c;
		background-color: #d60c8c;
		&:after{
			content: '';
			background-image: url('../img/arrow-right-white.svg');
			background-repeat: no-repeat;
			position: absolute;
			background-size: 100% 100%;
			top: 37%;
			right: 10%;
			width: 13px;
			height: 13px;
			transition: .3s;
			opacity: 1;
		}
		&:before{
			content: '';
			background-image: url('../img/arrow-right-pink.svg');
			background-repeat: no-repeat;
			position: absolute;
			background-size: 100% 100%;
			top: 37%;
			right: 10%;
			width: 13px;
			height: 13px;
			transition: .3s;
			opacity: 0;
		}
		&:hover{
			background-color: #fff;
			color: #d60c8c;
			&:after{
				opacity: 0;
			}
			&:before{
				opacity: 1;
			}
		}
	}
	&.huisregels{
		border: 2px solid #8dc63f;
		background-color: #8dc63f;
		&:after{
			content: '';
			background-image: url('../img/arrow-right-white.svg');
			background-repeat: no-repeat;
			position: absolute;
			background-size: 100% 100%;
			top: 37%;
			right: 10%;
			width: 13px;
			height: 13px;
			transition: .3s;
			opacity: 1;
		}
		&:before{
			content: '';
			background-image: url('../img/arrow-right-green.svg');
			background-repeat: no-repeat;
			position: absolute;
			background-size: 100% 100%;
			top: 37%;
			right: 10%;
			width: 13px;
			height: 13px;
			transition: .3s;
			opacity: 0;
		}
		&:hover{
			background-color: #fff;
			color: #8dc63f;
			&:after{
				opacity: 0;
			}
			&:before{
				opacity: 1;
			}
		}
	}
	&.bestuur{
		border: 2px solid #f47b20;
		background-color: #f47b20;
		&:after{
			content: '';
			background-image: url('../img/arrow-right-white.svg');
			background-repeat: no-repeat;
			position: absolute;
			background-size: 100% 100%;
			top: 37%;
			right: 10%;
			width: 13px;
			height: 13px;
			transition: .3s;
			opacity: 1;
		}
		&:before{
			content: '';
			background-image: url('../img/arrow-right-orange.svg');
			background-repeat: no-repeat;
			position: absolute;
			background-size: 100% 100%;
			top: 37%;
			right: 10%;
			width: 13px;
			height: 13px;
			transition: .3s;
			opacity: 0;
		}
		&:hover{
			background-color: #fff;
			color: #f47b20;
			&:after{
				opacity: 0;
			}
			&:before{
				opacity: 1;
			}
		}
	}
	&.button-nieuws{
		padding-left: 40px;
		padding-right: 20px;
		background-position: 10% center;
		@media (max-width: 768px){
			margin-top: 25px;
			display: inline-block;
		}
		&:after{
			content: '';
			background-image: url('../img/arrow-left-white.svg');
			background-repeat: no-repeat;
			position: absolute;
			background-size: 100% 100%;
			top: 37%;
			left: 7%;
			width: 13px;
			height: 13px;
			transition: .3s;
			opacity: 1;
		}
		&:before{
			content: '';
			background-image: url('../img/arrow-left-pink.svg');
			background-repeat: no-repeat;
			position: absolute;
			background-size: 100% 100%;
			top: 37%;
			left: 7%;
			width: 13px;
			height: 13px;
			transition: .3s;
			opacity: 0;
		}
		&:hover{
			background-color: #fff;
			color: #d60c8c;
			&:after{
				opacity: 0;
			}
			&:before{
				opacity: 1;
			}
		}
	}

	// &:hover, &:focus, &:active {
	// 	text-decoration: none;
	// 	color: #d60c8c;
	// 	background-color: transparent;
	// 	border: 2px solid #d60c8c;
	// 	background-image: url('../img/arrow-right-pink.svg');
	// 	background-size: 13px 13px;
	// 	// background-position: 93% center;
	// }

	&.see-project {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	&.reverse {
		padding-right: 30px;
		padding-left: 50px;
		background-position: 10% center;
		background-image: url('../img/arrow_white_reverse.svg');
		&:hover {
			background-image: url('../img/arrow_orange_reverse.svg');
		}
	}
}

.dashboard-header{
	height: 600px !important;
	@media(max-width: 992px){
		height: 450px !important;
	}
	@media(max-width: 767px){
		height: 350px !important;
	}
}

// header second
.header-container {
	background-position: top center;
	background-size: cover;
	position: relative;
	text-align: center;
	@media( max-width: 767px){
		height: 350px !important;
	}
	&:after {
		content: " ";
		width: 100%;
		height: 12px;
		display: block;
		background: url("../img/header-mask.jpg") no-repeat bottom center;
		background-size: 100% 12px;
		position: absolute;
		bottom: 0px;
		z-index: 2;
	}

	h1, h2 {
		color: $donker-blauw;
		font-size: 82px;
		font-weight: 600;
		padding: 15px;
		display: inline-block;
		@media (max-width: 767px) {
			font-size: 25px;
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}
	h1{
		background-color: #8dc63f;
		color: #fff;
	}

	h2 {
		color: #fff;
		font-size: 30px;
		margin-top: 8px;
		display: block;
		font-family: Roboto, sans-serif;
		font-weight: 400;
		@media (max-width: 767px) {
			font-size: 20px;
		}
	}

	p {
		font-size: 28px;
		color: #FFFFFF;
		padding-top: 20px;
		display: inline-block;
		font-weight: 400;
		margin-bottom: 30px;
		max-width: 50%;

		@media (max-width: 767px) {
			p {
				font-size: 22px;
			}
		}

		@media (max-width: 992px) {
			max-width: inherit;
		}
	}

	a.button {
		padding-top: 7px;
		padding-bottom: 7px;
		@media (max-width: 374px){
			margin-bottom: 15px;
		}
		&:after{
			text-decoration: none;
			background-repeat: no-repeat;
			top: 37%;
			right: 7%;
			width: 13px;
			height: 13px;
			background-image: url('../img/arrow-right-white.svg');
			opacity: 1;
		}
		&:before{
			text-decoration: none;
			background-repeat: no-repeat;
			top: 37%;
			right: 7%;
			width: 13px;
			height: 13px;
			background-image: url('../img/arrow-right-pink.svg');
			opacity: 0;
		}
		&:hover {
			color: #d60c8c;
			background-color: #FFFFFF;
			border-color: #FFFFFF;
			&:after{
				opacity: 0;
			}
			&:before{
				opacity: 1;
			}
		}
		&.parkeren{
			&:after{
				text-decoration: none;
				background-repeat: no-repeat;
				top: 37%;
				right: 7%;
				width: 13px;
				height: 13px;
				background-image: url('../img/arrow-right-white.svg');
				opacity: 1;
			}
			&:before{
				text-decoration: none;
				background-repeat: no-repeat;
				top: 37%;
				right: 7%;
				width: 13px;
				height: 13px;
				background-image: url('../img/arrow-right-orange.svg');
				opacity: 0;
			}
			&:hover {
				color: #f47b20;
				background-color: #FFFFFF;
				border-color: #FFFFFF;
				&:after{
					opacity: 0;
				}
				&:before{
					opacity: 1;
				}
			}
		}
	}

	&.header-home {
		padding-top: 100px;
		padding-bottom: 100px;

		@media (max-width: 992px) {
			padding-top: 80px;
		}

		@media (max-width: 767px) {
			padding-top: 50px;
			padding-bottom: 70px;
		}

		h1 {
			font-size: 40px;

			@media (max-width: 767px) {
				font-size: 25px;
			}
		}
	}
}

.flex{
	display: flex;
	flex-wrap: wrap;
}

// Content section
.content-section {
	padding-top: 70px;
	padding-bottom: 50px;
	.kinderfeestjes{
		padding-right: 75px;
	}
	.support-block{
		background-color: #d60c8c;
		padding-top: 28px;
		padding-bottom: 35px;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 18px;
		h2{
			color: #fff;
			font-size: 48px;
			margin-bottom: 10px;
			@media (max-width: 1200px){
				font-size: 38px;
			}
		}
		p{
			font-size: 18px;
			color: #fff;
		}
		.button{
			border-radius: 6px;
			background-color: #8dc63f;
			border: 2px solid #8dc63f;
			&:after{
				text-decoration: none;
				background-repeat: no-repeat;
				top: 37%;
				right: 7%;
				width: 13px;
				height: 13px;
				background-image: url('../img/arrow-right-white.svg');
				opacity: 1;
			}
			&:before{
				text-decoration: none;
				background-repeat: no-repeat;
				top: 37%;
				right: 7%;
				width: 13px;
				height: 13px;
				background-image: url('../img/arrow-right-green.svg');
				opacity: 0;
			}
			&:hover {
				color: #8dc63f;
				background-color: #FFFFFF;
				border-color: #FFFFFF;
				&:after{
					opacity: 0;
				}
				&:before{
					opacity: 1;
				}
			}
		}
	}
	.business-block-right{
		background-color: #00a5da;
		padding-top: 28px;
		padding-bottom: 35px;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 18px;
		h2{
			color: #fff;
			font-size: 48px;
			margin-bottom: 10px;
			@media (max-width: 1200px){
				font-size: 38px;
			}
		}
		p{
			font-size: 18px;
			color: #fff;
		}
		.button{
			border-radius: 6px;
			background-color: #f47b20;
			border-color: #f47b20;
			&:after{
				text-decoration: none;
				background-repeat: no-repeat;
				top: 37%;
				right: 7%;
				width: 13px;
				height: 13px;
				background-image: url('../img/arrow-right-white.svg');
				opacity: 1;
			}
			&:before{
				text-decoration: none;
				background-repeat: no-repeat;
				top: 37%;
				right: 7%;
				width: 13px;
				height: 13px;
				background-image: url('../img/arrow-right-orange.svg');
				opacity: 0;
			}
			&:hover {
				color: #f47b20;
				background-color: #FFFFFF;
				border-color: #FFFFFF;
				&:after{
					opacity: 0;
				}
				&:before{
					opacity: 1;
				}
			}
		}
	}
	.volunteers-block{
		background-color: #8dc63f;
		padding-top: 28px;
		padding-bottom: 35px;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 18px;
		h2{
			color: #fff;
			font-size: 48px;
			margin-bottom: 10px;
			@media (max-width: 1200px){
				font-size: 38px;
			}
		}
		p{
			font-size: 18px;
			color: #fff;
		}
		.button{
			border-radius: 6px;
			&:after{
				text-decoration: none;
				background-repeat: no-repeat;
				top: 37%;
				right: 7%;
				width: 13px;
				height: 13px;
				background-image: url('../img/arrow-right-white.svg');
				opacity: 1;
			}
			&:before{
				text-decoration: none;
				background-repeat: no-repeat;
				top: 37%;
				right: 7%;
				width: 13px;
				height: 13px;
				background-image: url('../img/arrow-right-green.svg');
				opacity: 0;
			}
			&:hover {
				color: #8dc63f;
				background-color: #FFFFFF;
				border-color: #FFFFFF;
				&:after{
					opacity: 0;
				}
				&:before{
					opacity: 1;
				}
			}
		}
	}
	.nodeco{
		text-decoration: none;
		color: #fff;
		&:hover{
			text-decoration: none;
		}
	}
	&.contactform-container {
		padding-top: 0;
	}

	h2 {
		font-size: 60px;
		font-weight: 600;
		color: #d60c8c;
		margin-bottom: 30px;
		padding-left: 15px;
		@media(max-width: 1200px){
			font-size: 44px;
		}
		&.toezicht{
			font-size: 48px;
			padding-top: 30px;
			padding-bottom: 30px;
			margin: 0;
			padding-left: 0px;
			@media(max-width: 767px){
				font-size: 36px;
			}
		}
		&.huisregels{
			font-size: 48px;
			color: #8dc63f;
			padding-top: 30px;
			padding-bottom: 30px;
			margin: 0;
			padding-left: 0px;
			@media(max-width: 767px){
				font-size: 36px;
			}
		}
		&.bestuur{
			font-size: 48px;
			color: #f47b20;
			padding-top: 30px;
			padding-bottom: 30px;
			margin: 0;
			padding-left: 0px;
			@media(max-width: 767px){
				font-size: 36px;
			}
		}
		&.history{
			color: #8dc63f;
			@media(max-width: 992px){
				margin-top: 30px;
			}
		}
	}

	h3 {
		font-size: 30px;
		font-weight: 600;
		color: $h2-main;
		margin-bottom: 5px;
		margin-top: 0px;
	}

	p {
		font-size: 18px;
		margin: 0 0 28px;
	}

	.carousel {

		@media (max-width: 992px) {
			margin-top: 30px;
		}
	}

	.contact-box {
		.inner-container {
			background: #E94D2D;
			padding-left: 40px;
			padding-top: 40px;
			padding-right: 50px;
			padding-bottom: 50px;
			background-image: url('../img/contact-box-bg.png');
			background-position: center right;
			background-size: 100%;

			@media (max-width: 992px) {
				padding: 20px;
			}

			h2 {
				font-family: 'Source Sans Pro', sans-serif;
				font-weight: 600;
				color: #FFFFFF;
				font-size: 30px;
				padding: 0px;
			}
			.button {
				background-color: #FFFFFF;
				color: #E94D2D;
				padding-top: 10px;
				padding-bottom: 10px;
				background-image: url('../img/arrow_right_orange.svg');
			}
		}
	}

	.widgets-sidebar {
		.inner-container:nth-child(1) {
			padding: 40px 50px 40px 40px;
			margin-bottom: 30px;
			background: #E94D2D url('../img/contact-box-bg.png') center right;
			background-size: 100%;

			@media (max-width: 992px) {
				padding: 20px;
			}

			h2 {
				font-family: 'Source Sans Pro', sans-serif;
				font-weight: 600;
				color: #FFFFFF;
				font-size: 30px;
				padding: 0px;
			}
			.button {
				background-color: #FFFFFF;
				color: #E94D2D;
				padding-top: 10px;
				padding-bottom: 10px;
				background-image: url('../img/arrow_right_orange.svg');
			}

			p:last-child {
				margin-bottom: 0;
			}
		}

		.inner-container:nth-child(2) {
			padding: 40px 50px 40px 40px;
			margin-bottom: 30px;
			background: #3E97D2 url('../img/quote-box-bg.png') center right;
			background-size: 100%;

			@media (max-width: 992px) {
				padding: 20px;
			}

			h2 {
				font-family: 'Source Sans Pro', sans-serif;
				font-weight: 600;
				color: #FFFFFF;
				font-size: 30px;
				padding: 0px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			p:last-child {
				margin-bottom: 0;
			}
		}

		.inner-container:nth-child(3) {
			h2 {
				font-size: 27px;
			}
			
			img {
				max-width: 35%;
				height: auto;
				margin-right: 5%;
				display: inline-block;
			}
		}
	}

	&.contactinfo ul {
		padding-left: 0px;
		margin-bottom: 30px;
		li {
			list-style: none;
			margin-right: 20px;
			font-size: 18px;
			font-weight: 300;
			line-height: 30px;
			background-position: left center;
			background-repeat: no-repeat;
			padding-left: 30px;
			background-image: url('../img/arrow-right.svg');
			background-size: 20px 15px;

			&:first-child {
				background-image: url('../img/phone.svg');
				background-size: 20px 20px;
			}
			&:last-child {
				background-image: url('../img/mail.svg');
				background-size: 20px 20px;
			}

			a {
				color: #585858;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	iframe.contact {
		width: 100%;
		margin-bottom: 30px;
	}

	@media (max-width: 992px) {
		padding-top: 40px;
		padding-bottom: 40px;

		h2 {
			font-size: 48px;
		}
		h3 {
			font-size: 23px;
			margin-top: 0px;
			margin-bottom: 20px;
		}
	}
	@media (max-width: 767px) {
		h2{
			font-size: 36px;
		}
	}	

	.img-control {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 5px;
		top: 5px;
		background: #FFFFFF no-repeat center center;
		background-size: 20px 16px;
		transition: all .3s;
		&:hover {
			background-color: #E8E8E8;
		}
		&.go-to-left {
			right: 55px;
			background-image: url('../img/prev.svg');
		}
		&.go-to-right {
			background-image: url('../img/next.svg');
		}
	}
	.business-block{
		img{
			border: 2px solid #e3e3e3;
		}
	}
}

// Work section
.work {
	padding-top: 60px;
	padding-bottom: 0;

	@media (max-width: 767px) {
		padding-top: 40px;
	}

	&.work-second {
		padding-top: 0;
		padding-bottom: 60px;
	}

	h2 {
		color: $donker-blauw;
		font-size: 48px;
		margin: 0;
	}
	h3 {
		color: $licht-blauw;
		font-size: 32px;
		padding-top: 10px;
		margin-bottom: 50px;

		@media (max-width: 767px) {
			font-size: 28px;
			margin-bottom: 30px;
			padding: 0;
		}

		@media (max-width: 992px) {
			margin-bottom: 30px;
			padding: 0;
		}
	}
	h4 {
		color: $licht-blauw;
		font-size: 32px;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.text-center {
		@media (max-width: 767px) {
			text-align: left;
		}
	}

	.block1 {
		// text-align: center;
		margin-bottom: 30px;

		img {
			width: 100%;
			height: auto;
		}
		p {
			font-size: 18px;
			font-weight: 300;
			margin-bottom: 30px;
		}
	}

	@media (max-width: 992px) {

		h4 {
			font-size: 21px;
		}

		p {
			font-size: 17px;
		}
	}

	@media (max-width: 767px) {

		h2 {
			font-size: 38px;
		}
		h4 {
			font-size: 30px;
		}
	}
}

// News section
.news {
	padding-top: 60px;
	padding-bottom: 60px;
	.news-block{
		&:nth-child(1){
			h4{
				font-size: 38px;
				margin-bottom: -14px;
			}
			.button{
				border: 2px solid #d60c8c;
				background-color: #d60c8c;
				border-radius: 6px;
				&:after{
					content: '';
					background-image: url('../img/arrow-right-white.svg');
					background-repeat: no-repeat;
					position: absolute;
					background-size: 100% 100%;
					top: 37%;
					right: 10%;
					width: 13px;
					height: 13px;
					transition: .3s;
					opacity: 1;
				}
				&:before{
					content: '';
					background-image: url('../img/arrow-right-pink.svg');
					background-repeat: no-repeat;
					position: absolute;
					background-size: 100% 100%;
					top: 37%;
					right: 10%;
					width: 13px;
					height: 13px;
					transition: .3s;
					opacity: 0;
				}
				&:hover{
					color: #d60c8c;
					background-color: transparent;
					&:after{
						opacity: 0;
					}
					&:before{
						opacity: 1;
					}
				}
			}
		}
		&:nth-child(2){
			h4{
				font-size: 38px;
				margin-bottom: -14px;
				color: #8dc63f;
			}
			.button{
				background-color: #8dc63f;
				border-radius: 6px;
				border: 2px solid #8dc63f;
				&:after{
					content: '';
					background-image: url('../img/arrow-right-white.svg');
					background-repeat: no-repeat;
					position: absolute;
					background-size: 100% 100%;
					top: 37%;
					right: 10%;
					width: 13px;
					height: 13px;
					transition: .3s;
					opacity: 1;
				}
				&:before{
					content: '';
					background-image: url('../img/arrow-right-green.svg');
					background-repeat: no-repeat;
					position: absolute;
					background-size: 100% 100%;
					top: 37%;
					right: 10%;
					width: 13px;
					height: 13px;
					transition: .3s;
					opacity: 0;
				}
				&:hover{
					color: #8dc63f;
					background-color: transparent;
					&:after{
						opacity: 0;
					}
					&:before{
						opacity: 1;
					}
				}
			}
		}
		&:nth-child(3){
			h4{
				font-size: 38px;
				margin-bottom: -14px;
				color: #f47b20;
			}
			.button{
				background-color: #f47b20;
				border-radius: 6px;
				border: 2px solid #f47b20;
				&:after{
					content: '';
					background-image: url('../img/arrow-right-white.svg');
					background-repeat: no-repeat;
					position: absolute;
					background-size: 100% 100%;
					top: 37%;
					right: 10%;
					width: 13px;
					height: 13px;
					transition: .3s;
					opacity: 1;
				}
				&:before{
					content: '';
					background-image: url('../img/arrow-right-orange.svg');
					background-repeat: no-repeat;
					position: absolute;
					background-size: 100% 100%;
					top: 37%;
					right: 10%;
					width: 13px;
					height: 13px;
					transition: .3s;
					opacity: 0;
				}
				&:hover{
					color: #f47b20;
					background-color: transparent;
					&:after{
						opacity: 0;
					}
					&:before{
						opacity: 1;
					}
				}
			}
		}
	}
	h2 {
		color: $donker-blauw;
		font-size: 48px;
		margin-bottom: 50px;
	}
	h4 {
		color: #d60c8c;
		font-size: 32px;
		margin-top: 20px;
		margin-bottom: 20px;
		&.news-item3{
			color: #8dc63f;
		}
		&.news-item4{
			color: #8dc63f;
		}
	}

	.large {
		margin-bottom: 80px;
		display: flex;
		.img-l {
			display: block;
			background-size: cover;
			background: no-repeat center center;
			min-height: 440px;
		}
		.grey-b {
			background-color: #E8E8E8;
			padding: 50px;
			p {
				font-size: 18px;
				margin-bottom: 30px;
			}
			h4 {
				margin-top: 0;
			}
		}
	}
	.img-m {
		display: block;
		background-size: cover;
		width: 100%;
		height: 160px;
	}
	.block1 {
		// text-align: center;
		margin-bottom: 80px;
		position: relative;

		img {
			width: 100%;
			height: auto;
		}
		p {
			font-size: 18px;
			font-weight: 300;
			margin-bottom: 30px;
		}

		.button {
			position: absolute;
			bottom: 0;
		}
	}

	@media (max-width: 992px) {
		padding-top: 40px;
		padding-bottom: 40px;

		h4 {
			font-size: 21px;
		}

		p {
			font-size: 17px;
		}

		.block1 {
			margin-bottom: 30px;
		}

		.large {
			.img-l {

				min-height: 0px;
			}
		}
	}

	@media (max-width: 767px) {
		h2 {
			font-size: 38px;
		}
		h3 {
			font-size: 28px;
			margin-bottom: 30px;
		}
		h4 {
			font-size: 30px;
		}

		.block1 .button {
			position: relative;
		}

		.large {
			display: block;
			.img-l {

				min-height: 250px;
			}
			.grey-b {
				padding: 20px;
			}
		}
	}
}

// What do we do section
.whatwedo {
	background-image: url('../img/goed-background.jpg');
	background-size: 100% 100%;
	background-position: center top;
	background-repeat: no-repeat;
	padding-top: 150px;
	padding-bottom: 100px;

	.row {
		display: flex;
	}

	.white-bg {
		background-color: #FFFFFF;
		padding-left: 90px;
		padding-right: 70px;
		padding-top: 70px;
		padding-bottom: 70px;
		h2 {
			color: #d60c8c;
			font-size: 72px;
			margin-bottom: 35px;
		}
		.redactor-editor > ul,
		.inline-edit > ul,
		.inner-container > ul {
			font-size: 0;
			margin: 0;
			padding: 0;

			li {
				width: 50%;
				list-style: none;
				// margin-right: 20px;
				font-size: 18px;
				font-weight: 300;
				display: inline-block;
				padding-top: 15px;
				padding-bottom: 15px;
				background-image: url('../img/check.svg');
				background-size: 28px 28px;
				background-position: left center;
				background-repeat: no-repeat;
				padding-left: 40px;

				@media (max-width: 767px) {
					width: 100%;
					display: block;
					padding-top: 10px;
					padding-bottom: 10px;
				}
			}
		}
	}

	.whatwedo-img {
		position: relative;
		overflow: hidden;
		img {
			position: absolute;
			height: 100%;
			width: auto;
			max-width: none;
			min-width: 100%;
			@media(max-width: 992px){
				display: none;
			}
		}

		.sitekick-upload-container .images-list .thumbnail-holder {
			margin: 0;
			width: 100%;
		}
	}

	//.whatwedo-img img,
	//.whatwedo-img .sitekick-upload-container .single-upload-container .images-list .thumbnail img {
	//	width: 100%;
	//	height: auto;
	//}

	@media (max-width: 992px) {
		padding-top: 80px;
		padding-bottom: 80px;

		.row {
			display: inherit;
		}

		.white-bg {
			overflow: auto;
		}
	}

	@media (max-width: 767px) {
		background-size: cover;
		background-position: 25% top;

		.white-bg {
			overflow: auto;
			padding: 30px 30px;
			margin: 0 15px;

			h2 {
				font-size: 32px;
				margin-bottom: 30px;
			}
		}
	}
}

// Projects
.projects {
	padding-top: 70px;
	padding-bottom: 80px;

	h2 {
		color: $donker-blauw;
		font-size: 48px;
		margin-bottom: 60px;
	}

	h4 {
		color: $licht-blauw;
		font-size: 32px;
		margin-top: 20px;
		margin-bottom: 20px;

		@media (max-width: 992px) {
			font-size: 21px;
		}

		@media (max-width: 767px) {
			font-size: 30px;
		}
	}

	p {
		font-size: 18px;
		font-weight: 300;
		margin-top: 20px;
		margin-bottom: 30px;
	}

	img {
		width: 100%;
		height: auto;
	}

	.img-m {
		height: 180px;
		width: 100%;
		display: block;
		background-size: cover;
	}

	.link {
		font-size: 21px;
		font-family: 'Source Sans Pro', sans-serif;
		font-weight: 600;
		color: #28678E;
		background-image: url('../img/arrow-blue.svg');
		background-size: 13px 13px;
		background-position: left center;
		background-repeat: no-repeat;
		padding-left: 20px;
		&:hover {
			text-decoration: none;
		}
	}
	.more-projects {
		width: 125px;
		height: 125px;
		margin-top: 15px;
		margin-left: 50px;
	}

	.slick-next2 {
		width: 100px;
		height: 100px;
		background-color: #EBEBEB;
		border-radius: 50%;
		border: none;
		box-shadow: 1.118px 1.658px 2px 0px rgba(0, 0, 0, 0.17);
		position: absolute;
		right: -150px;
		top: 10%;
		// bottom: 0;
		// margin: auto 0;
		background-image: url('../img/arrow-right.svg');
		background-size: 50px 40px;
		background-position: center center;
		background-repeat: no-repeat;
		outline: none;
		outline-style: none;
	}

	@media (max-width: 992px) {
		padding-top: 50px;
		padding-bottom: 50px;

		h2 {
			margin-bottom: 30px;
		}
	}

	@media (max-width: 767px) {
		padding-top: 30px;
		padding-bottom: 30px;
		overflow: hidden;

		h2 {
			font-size: 32px;
		}
		.slick-next2 {
			right: 30px;
			height: 50px;
			width: 50px;
			background-size: 30px 20px;
			top: 15%;
			margin: 0;
		}
		.link {
			margin-top: 30px;
			display: inline-block;
		}
	}
}

// Footer
.footer {
	background-image: url('../img/footer-background.jpg');
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	padding-top: 140px;
	padding-bottom: 100px;
	color: #fff;
	@media (max-width: 992px) {
		padding-top: 70px;
		padding-bottom: 30px;
	}

	@media (max-width: 767px) {
		background-size: cover;
		background-position: 30% center;
		padding-top: 50px;
	}

	.footer-button {
		@media (max-width: 992px) {
			font-size: 16px;
		}
	}

	.footer-logos{
		.facebook{
			width: 41px;
			margin-right: 3px;
			display: inline-block;
			margin-top: 18px;
		}
		.twitter{
			width: 41px;
			display: inline-block;
			margin-top: 18px;
		}
		@media(max-width: 767px){
			margin-top: 40px;
			text-align: center;
		}
	}

	h2 {
		color: #fff;
		font-size: 60px;
		margin-bottom: 40px;

		@media (max-width: 767px) {
			font-size: 27px;
		}

		@media (max-width: 992px) {
			font-size: 28px;
			margin-bottom: 25px;
			margin-top: 30px;
		}
	}

	p {
		font-size: 18px;
		font-weight: 300;
		margin-bottom: 20px;
	}

	ul {
		padding-left: 0px;
		margin-bottom: 30px;
		li {
			list-style: none;
			margin-right: 20px;
			font-size: 18px;
			font-weight: 300;
			line-height: 30px;
			padding-left: 30px;
			background: url('../img/arrow-right.svg') no-repeat left 8px;
			background-size: 20px 15px;

			&.phone {
				background-image: url('../img/phone.svg');
				background-size: 20px 20px;
			}
			&.mail {
				background-image: url('../img/mail.svg');
				background-size: 20px 20px;
			}

			a {
				color: #585858;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
	.button{
		&.parking{
			background-color: #fff;
			color: #f47b20;
			border-radius: 6px;
			border: 2px solid #fff;
			&:after{
				content: '';
				background-image: url('../img/arrow-right-orange.svg');
				background-repeat: no-repeat;
				position: absolute;
				background-size: 100% 100%;
				top: 37%;
				right: 10%;
				width: 13px;
				height: 13px;
				transition: .3s;
				opacity: 1;
			}
			&:before{
				content: '';
				background-image: url('../img/arrow-right-white.svg');
				background-repeat: no-repeat;
				position: absolute;
				background-size: 100% 100%;
				top: 37%;
				right: 10%;
				width: 13px;
				height: 13px;
				transition: .3s;
				opacity: 0;
			}
			&:hover{
				color: #fff;
				background-color: transparent;
				&:after{
					opacity: 0;
				}
				&:before{
					opacity: 1;
				}
			}
		}
		&.times{
			background-color: #fff;
			color: #d60c8c;
			border-radius: 6px;
			border: 2px solid #fff;
			margin-right: 10px;
			&:after{
				content: '';
				background-image: url('../img/arrow-right-pink.svg');
				background-repeat: no-repeat;
				position: absolute;
				background-size: 100% 100%;
				top: 37%;
				right: 10%;
				width: 13px;
				height: 13px;
				transition: .3s;
				opacity: 1;
			}
			&:before{
				content: '';
				background-image: url('../img/arrow-right-white.svg');
				background-repeat: no-repeat;
				position: absolute;
				background-size: 100% 100%;
				top: 37%;
				right: 10%;
				width: 13px;
				height: 13px;
				transition: .3s;
				opacity: 0;
			}
			&:hover{
				color: #fff;;
				background-color: transparent;
				&:after{
					opacity: 0;
				}
				&:before{
					opacity: 1;
				}
			}
			@media (max-width: 1200px){
				margin-bottom: 15px;
			}
		}
	}
	img{
		display: block;
		margin: 0 auto;
		max-width: 300px;
		@media (max-width: 767px){
			max-width: 200px;
			margin-bottom: 40px;
		}
	}
	a{
		font-weight: bold;
		color: #fff;
		font-size: 18px;
		text-decoration: none;
	}
}

// Footer second
.footer-second {
	padding: 30px 0;

	img {
		width: 100%;
		max-width: 210px;
	}

	.sjonic {
		text-align: center;
		margin: 13px 0;

		@media (min-width: 992px) {
			text-align: right;
			margin: 21px 0;
		}
	}
}

.pagecontainer-16{
	background-color: #14acdd;
	line-height: 29px;
	h2{
		color: #fff;
		font-size: 52px;
	}
	p{
		color: #fff;
		font-size: 18px;
	}
	ul{
		padding: 0px;
		list-style: none;
		li{
			color: #fff;
			font-size: 18px;
	 		background-image: url('../img/arrow-white.svg');
	 		background-repeat: no-repeat;
	 		background-size: 30px;
	 		background-position: left center;
	 		padding-left: 40px;
		}
	}
}

.pagecontainer-18{
	ul{
		font-size: 18px;
		margin-bottom: 28px;
	}
}

.pagecontainer-11{
	.button{
		border-radius: 6px;
	}
	h2{
		padding-left: 30px;
	}
}

.parkeren-anchor{
	content: "";
    display: block;
    height: 250px;
    margin: -250px 0 0;
}
.inner-container{
	h2{
		padding: 0;
	}
}

.news-item{
	h2{
		padding-left: 0px;
	}
}

.no-p-l{
	padding-left: 0px;
	@media(max-width: 992px){
		padding-right: 0px;
	}
}

.no-p-r{
	@media(max-width: 992px){
		padding: 0px;
	}
}

.pagecontainer-5{
	h3{
		font-size: 60px;
		color: #d60c8c;
		margin-bottom: 25px;
	}
	select{
		margin-bottom: 25px;
		background-color: #D9D9D9;
    	// border: 1px solid #D9D9D9;
    	height: 55px;
    	font-size: 18px;
    	outline: none;
    	border:none;
	}
}

.pagecontainer-10{
	p{
		a{
			color: #585858;
			text-decoration: underline;
			&:hover{
				color: #585858;
			}
		}
	}
}

a#materialen{
	&:before {
		content:"";
		display:block;
		height:250px; /* fixed header height*/
		margin:-250px 0 0; /* negative fixed header height */
	}
}

a#bestuur-anchor{
	&:before {
		content:"";
		display:block;
		height:250px; /* fixed header height*/
		margin:-250px 0 0; /* negative fixed header height */
	}
}

.pagecontainer-24{
	ul{
		li{
			font-size: 18px;
		}
	}
}

// Sjonic
//.sjonic {
//	text-align: center;
//	a {
//		text-decoration: none;
//		color: #000000;
//		&:hover {
//			text-decoration: none;
//			color: #000000;
//		}
//	}
//	padding-bottom: 15px;
//}



